import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';


const SinglePayment = ({ payment, setPayment, onSubmit, isEdit }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayment((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  return (
    <div className="form-group">
      <div className="p-fluid">

        <div className="p-field">
          <label htmlFor="amount">Amount</label>
          <InputNumber
            id="amount"
            name="amount"
            value={payment.amount}
            onValueChange={handleChange}
            // mode="currency"
            // currency="PKR"
          />
        </div>
        
        <Button
          label={isEdit ? 'Update Payment' : 'Add Payment'}
          className='btn-primary mt-2'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SinglePayment;
