import React, { useState, useEffect } from "react";
import { getDeviceBrands } from "../../api/deviceBrandApi";
import { getDeviceModels } from "../../api/deviceModelApi";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { getUsers } from "../../api/userApi";

// prettier-ignore
const SingleChild = ({ child, setChild, onSubmit, isEdit,showParents }) => {

  const [haBrandOptions, setHaBrandOptions] = useState([]);
  const [ciBrandOptions, setCiBrandOptions] = useState([]);
  const [haModelOptions, setHaModelOptions] = useState([]);
  const [filteredModelsRightEar, setFilteredModelsRightEar] = useState([]);
  const [filteredModelsLeftEar, setFilteredModelsLeftEar] = useState([]);
  const [filteredModelsCI, setFilteredModelsCI] = useState([]);
  const [allParents,setAllParents]=useState([])


  useEffect(() => {

    fetchDeviceBrands();
    fetchDeviceModels();
    fetchParents()
  }, []);

  const fetchDeviceBrands = async () => {
    const response = await getDeviceBrands();
    const haBrands = response.filter(brand => brand.deviceType === "Hearing Aid");
    const ciBrands = response.filter(brand => brand.deviceType === "Cochlear Implant");
    setHaBrandOptions(haBrands);
    console.log(haBrandOptions);
    
    setCiBrandOptions(ciBrands);
    // console.log("haBrandOptions", haBrandOptions);
  };
  const fetchDeviceModels = async () => {
    const response = await getDeviceModels();
    setHaModelOptions(response);
  };
  const fetchParents = async () => {
    try {
      const users = await getUsers();
      const parents = users.filter((user) => user.roleId === 3);
      setAllParents(parents);
      console.log(allParents);
      
    } catch (error) {
      console.error("Error fetching Parents", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setChild((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log({
    //   [name]: value,
    // });
  };

  const handleBrandChange = (e, earType) => {
    const { value } = e.target;

    // Fetch the filtered models based on the selected brand
    const filteredModels = haModelOptions.filter(model => model.DeviceBrandId === value);
    // console.log("filteredModels", filteredModels);
    // console.log("haModelOptions", haModelOptions);
    // Update the relevant filtered models state based on earType
    if (earType === 'right') {
      setFilteredModelsRightEar(filteredModels);
      setChild({ ...child, haBrandRightEarId: value, haModelRightEarId: null }); // Reset model selection
    } else if (earType === 'left') {
      setFilteredModelsLeftEar(filteredModels);
      setChild({ ...child, haBrandLeftEarId: value, haModelLeftEarId: null }); // Reset model selection
    } else if (earType === 'ci') {
      setFilteredModelsCI(filteredModels);
      setChild({ ...child, CIBrandId: value, CIModelId: null }); // Reset model selection
    }
  };

  const genderOptions = [
    { name: "Male", id: "male" },
    { name: "Female", id: "female" },
  ]

  const booleanOptions = [
    { name: "No", id: 0 },
    { name: "Yes", id: 1 },
  ]

  const languages = [
    { name: "Urdu", id: "urud" },
    { name: "English", id: "english" },
  ]

  const modeOfCommunication = [
    { name: "Verbal", id: "verbal" },
    { name: "Non Verbal", id: "non_verbal" },
  ]

  const demographicFields = [
    showParents && { id: 'parentId', label: 'Parent', component: Dropdown, options: allParents, className: 'col-12' },
    { id: 'fullName', label: 'Full Name', component: InputText, type: 'text', className: 'col-6' },
    { id: 'dateOfBirth', label: 'Date of Birth', component: Calendar, type: 'date', className: 'col-6' },
    { id: 'gender', label: 'Gender', component: Dropdown, options: genderOptions, className: 'col-6' },
    { id: 'diagnosis', label: 'Diagnosis', component: InputText, type: 'text', className: 'col-6' },
    { id: 'fatherName', label: "Father's Name", component: InputText, type: 'text', className: 'col-6' },
    { id: 'fatherContact', label: "Father's Contact", component: InputText, type: 'text', className: 'col-6' },
    { id: 'motherName', label: "Mother's Name", component: InputText, type: 'text', className: 'col-6' },
    { id: 'motherContact', label: "Mother's Contact", component: InputText, type: 'text', className: 'col-6' },
    { id: 'fatherOccupation', label: "Father's Occupation", component: InputText, type: 'text', className: 'col-6' },
    { id: 'motherOccupation', label: "Mother's Occupation", component: InputText, type: 'text', className: 'col-6' },
    { id: 'address', label: "Address", component: InputTextarea, type: 'text', className: 'col-12' },
    { id: 'familySystem', label: "Family System", component: InputText, type: 'text', className: 'col-4' },
    { id: 'siblings', label: "Siblings", component: InputNumber, type: 'number', className: 'col-4' },
    { id: 'birthOrder', label: "Birth Order", component: InputText, type: 'text', className: 'col-4' },
  ].filter(Boolean);

  const medicalHistoryFields = [
    { id: 'birthHistory', label: 'Birth History', component: InputText, type: 'text' },
    { id: 'currentMedications', label: 'Current Medications', component: InputText, type: 'text' },
    { id: 'allergies', label: 'Allergies', component: InputText, type: 'text' },
    { id: 'mainConcerns', label: 'Current Symptoms/Concerns', component: InputTextarea, type: 'text' },
    { id: 'recommendation', label: 'Recommendation', component: InputTextarea, type: 'text' },
    // Add more fields as needed
  ];

  const haCiInformationFields = [
    { id: 'hearingAge', label: 'Hearing Age', component: InputText, type: 'text', className: 'col-6' },
    { id: 'dateOfHLDiagnosis', label: 'Date Of HL Diagnosis', component: Calendar, type: 'date', className: 'col-6' },
    { id: 'causeOfHL', label: 'Cause Of HL', component: InputText, type: 'text', className: 'col-12' },
    { id: 'hlRightEar', label: 'HL in right ear (dB)', component: InputText, type: 'text', className: 'col-6' },
    { id: 'hlLeftEar', label: 'HL in left ear (dB)', component: InputText, type: 'text', className: 'col-6' },
    { id: 'dateOfHAUse', label: 'Date of HA Use', component: Calendar, type: 'date', className: 'col-6' },
    { id: 'isHLProgressive', label: 'Is HL progressive?', component: Dropdown, options: booleanOptions, className: 'col-6' },
    { id: 'haBrandRightEarId', label: 'HA Brand Right Ear', component: Dropdown, options: haBrandOptions, className: 'col-6' },
    { id: 'haModelRightEarId', label: 'HA Model Right Ear', component: Dropdown, options: haModelOptions, className: 'col-6' },
    { id: 'haBrandLeftEarId', label: 'HA Brand Left Ear', component: Dropdown, options: haBrandOptions, className: 'col-6' },
    { id: 'haModelLeftEarId', label: 'HA Model Left Ear', component: Dropdown, options: haModelOptions, className: 'col-6' },
    { id: 'dateOfCIRightEar', label: 'Date of CI right Ear (if  applicable)', component: Calendar, type: 'date', className: 'col-6' },
    { id: 'dateOfCILeftEar', label: 'Date of CI left Ear (if  applicable)', component: Calendar, type: 'date', className: 'col-6' },
    { id: 'CIBrandId', label: 'Which CI Brand', component: Dropdown, options: ciBrandOptions, className: 'col-6' },
    { id: 'CIModelId', label: 'Which Processor', component: Dropdown, options: haModelOptions, className: 'col-6' },

    // Add more fields as needed
  ];

  const otherFields = [
    { id: 'chosenModeOfCommunication', label: 'Chosen Mode of Communication', component: Dropdown, options: modeOfCommunication, className: 'col-6' },
    { id: 'nativeLanguage', label: 'Native Language', component: Dropdown, options: languages, className: 'col-6' },
    { id: 'isGoingSchool', label: 'Is Going to School', component: Dropdown, options: booleanOptions, className: 'col-3' },
    { id: 'grade', label: 'Grade', component: InputText, className: 'col-3' },
    { id: 'languageSpokenInSchool', label: 'Language spoken in school', component: Dropdown, options: languages, className: 'col-6' },
    // Add more fields as needed
  ];

  const renderFields = (fields) => {
    return fields.map((field) => {
      const FieldComponent = field.component;
      const fieldType = field.type;
      const isModelField = field.id.includes('Model');
      return (
        <div key={field.id} className={field.className} >
          <label htmlFor={field.id} style={{ display: 'block', marginBottom: '0.5rem' }}>{field.label}</label>
          {
            fieldType === 'text' ? (
                <FieldComponent
                  id={field.id}
                  name={field.id}
                  value={child[field.id]}
                  // onChange={(e) => setChild({ ...child, [field.id]: e.target.value })}
                  onChange={handleChange}
                  className="full-width"
                />
              ):
              fieldType === 'number' ? (
                <FieldComponent
                  id={field.id}
                  name={field.id}
                  value={child[field.id]}
                  onChange={(e) => setChild((prevState) => ({
                  ...prevState,
                  [field.id]: e.value, // Update state with the new value
                  }))}
                  className="full-width"
                />
              ) : (
                <FieldComponent
                  id={field.id}
                  name={field.id}
                  value={child[field.id]}
                  // options={field.options}
                  options={isModelField
                    ? field.id.includes('RightEar')
                      ? filteredModelsRightEar
                      : field.id.includes('LeftEar')
                        ? filteredModelsLeftEar
                        : filteredModelsCI
                    : field.options}
                  onChange={(e) =>
                    field.id.includes('Brand')
                      ? handleBrandChange(e, field.id.includes('RightEar') ? 'right' : field.id.includes('LeftEar') ? 'left' : 'ci')
                      : handleChange(e)
                  }
                  optionLabel='name'
                  optionValue='id'
                  // onChange={handleChange}
                  className="full-width"
                />
              )}
        </div>

      );
    });
  };



  return (
    // className="form-group"
    <div>

      <TabView orientation="left">
        <TabPanel header="Demographic Information">
          {/* {renderFields(demographicFields)} */}
          <div className="grid">
            {renderFields(demographicFields)}
          </div>
        </TabPanel>
        <TabPanel header="Medical History">
          {renderFields(medicalHistoryFields)}
        </TabPanel>
        <TabPanel header="HA/CI Information">
          <div className="grid">
            {renderFields(haCiInformationFields)}
          </div>
        </TabPanel>
        <TabPanel header="Others">
          <div className="grid">
            {renderFields(otherFields)}
          </div>
        </TabPanel>
      </TabView>
      <Button
        label={isEdit ? 'Update Child' : 'Add Child'}
        className='btn-primary mt-2 full-width'
        onClick={onSubmit}
      />
    </div>
  );
};

export default SingleChild;
