import React, { useEffect, useState } from "react";
import DataListing from "../Common/DataListing";
import { Dialog } from "primereact/dialog";
import SingleAppointment from "./SingleAppointment";
import { getUsers } from "../../api/userApi";
import { useAuth } from "../../contexts/AuthContext";
import {
  addAppointment,
  deleteAppointment,
  editAppointment,
  getAllAppointments,
  updateAppointmentField,
} from "../../api/appointmentApi";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/dateutils";
import {
  formatDuration,
  formatTimeTo12HourFormat,
} from "../../utils/generalutils";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

const AppointmentsList = () => {
  const { user } = useAuth();
  const [allTherapists, setAllTherapists] = useState([]);
  const [allParents, setAllParents] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [appointmentModalVisible, setAppointmentModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [newAppointment, setNewAppointment] = useState({
    date: "",
    time: "",
    duration: 30,
    // endTime: "",
    therapistId: "",
    parentId: "",
    mainConcerns: "",
    notes: "",
  });
  const [{ refreshData, activeEditor, editorText }, setState] = useState({
    refreshData: false,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });
  const statusTypes = [
    { label: "Pending", value: "pending" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Completed", value: "completed" },
  ];
  const responseAlert = (response, autoClose) => {
    if (response.success) {
      toast.success(response.msg, { autoClose: autoClose });
    } else {
      toast.error(response.msg, { autoClose: autoClose });
    }
  };
  const getAppointments = async () => {
    try {
      const response = await getAllAppointments();
      if (!response) {
        console.error("Error In Getting Response  ");
        return;
      }
      if (user?.roleId !== undefined && user.roleId <= 2) {
        setAppointments(response);
      } else {
        const filteredAppointments = response.filter(
          (appointment) =>
            appointment.parentId === user.id ||
            appointment.therapistId === user.id
        );
        setAppointments(filteredAppointments);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getTherapistsAndParents();
    getAppointments();
  }, []);

  const getTherapistsAndParents = async () => {
    try {
      const users = await getUsers();
      const therapists = users.filter((user) => user.roleId === 4);
      const parents = users.filter((user) => user.roleId === 3);
      setAllTherapists(therapists);
      setAllParents(parents);
    } catch (error) {
      console.error("Error fetching Users", error);
    }
  };
  const getAppointmentTimeRange = (appointment) => {
    const startMinutes = getTotalMinutes(appointment.time);
    const endMinutes = startMinutes + appointment.duration;
    return { startMinutes, endMinutes };
  };
  const getTotalMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const hasConflict = (newAppointment, existingAppointments) => {
    const newDate = new Date(newAppointment.date).toDateString();
    const newAppointmentRange = getAppointmentTimeRange(newAppointment);

    for (const existing of existingAppointments) {
      const existingDate = new Date(existing.date).toDateString();
      if (
        newDate === existingDate &&
        newAppointment.therapistId === existing.therapistId
      ) {
        const existingRange = getAppointmentTimeRange(existing);
        if (
          newAppointmentRange.startMinutes < existingRange.endMinutes &&
          newAppointmentRange.endMinutes > existingRange.startMinutes
        ) {
          return true; // Conflict detected
        }
      }
    }
    return false;
  };

  const handleCreate = () => {
    setAppointmentModalVisible(true);
    if (user.roleId === 3) {
      setNewAppointment({
        ...newAppointment,
        parentId: user.id,
      });
    }
  };
  const handleEdit = (rowData) => {
    if (user.roleId === 3 && rowData.status !== "pending") {
      toast.error("You Can't Edit This Appointment ", {
        autoClose: 2000,
      });
      return;
    } else {
      setNewAppointment(rowData);
      setEditMode(true);
      setAppointmentModalVisible(true);
    }
  };
  const handleDelete = async (id) => {
    try {
      await deleteAppointment(id);
      await getAppointments();
    } catch (err) {
      console.log(err);
    }
  };
  const checkTimeAvailability = () => {
    const appointmentsToCheck = appointments.filter(
      (appointment) => appointment.id !== newAppointment.id
    );
    if (hasConflict(newAppointment, appointmentsToCheck)) {
      toast.error(
        "Not Available ",
        // "This therapist is already booked at the selected time. Please choose another time or other Therapist.",
        { autoClose: 2000 }
      );
      return;
    } else {
      toast.success(" Available ", {
        // toast.success("Time You Have Selected is Available for Appointment", {
        autoClose: 2000,
      });
    }
  };
  const handleSaveAppointment = async (e) => {
    e.preventDefault();
    const appointmentsToCheck = appointments.filter(
      (appointment) => appointment.id !== newAppointment.id
    );
    if (hasConflict(newAppointment, appointmentsToCheck)) {
      toast.error(
        "Not Available",
        // "This therapist is already booked at the selected time. Please choose another time or other Therapist.",
        { autoClose: 700 }
      );
      return;
    }
    try {
      let response = {};
      if (editMode) {
        response = await editAppointment(newAppointment.id, newAppointment);
      } else {
        response = await addAppointment(newAppointment);
      }
      if (response) {
        setAppointmentModalVisible(false);
        responseAlert(response, 500);
        setEditMode(false);
        setNewAppointment({
          date: "",
          time: "",
          duration: 30,
          therapistId: "",
          parentId: "",
          mainConcerns: "",
          notes: "",
        });
        await getAppointments();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const dateBody = (rowData) => {
    return <div>{formatDate(rowData.date)}</div>;
  };
  const timeBody = (rowData) => {
    return <div>{formatTimeTo12HourFormat(rowData.time)}</div>;
  };

  const durationBody = (rowData) => {
    return <div>{formatDuration(rowData.duration)}</div>;
  };
  const handleCancelEdit = () => {
    setStatusType("");
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };
  const handleUpdateField = async (id, field, newValue) => {
    try {
      const response = await updateAppointmentField(id, newValue, field);

      if (response.success) {
        await getAppointments();
        toast.success(`Status Updated to ${statusType}`, { autoClose: 2000 });
        setStatusType("");
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatusUpdate = async (rowData, field) => {
    await editAppointment(rowData.id, { status: statusType });
    // await handleUpdateField(rowData.id, field, statusType);
    await getAppointments();
    setStatusType("");
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
      refreshData: !prevState.refreshData,
    }));
  };
  const getStatusEditor = (rowData, field) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          id="couponType"
          value={statusType}
          options={statusTypes}
          onChange={(e) => setStatusType(e.value)}
          placeholder="Select Status"
          required
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleStatusUpdate(rowData, field)}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={handleCancelEdit}
        />
      </div>
    );
  };
  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };
  const statusFilterOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Completed", value: "Completed" },
  ];

  const statusFilter = (options) => (
    <Dropdown
      value={options.value}
      options={statusFilterOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Select a Status"
      className="p-column-filter"
    />
  );
  const statusBody = (rowData) => {
    const field = "status";
    const StatusfirstLetterUpperCase = rowData[field]
      ?.slice(0, 1)
      .toUpperCase();

    return user.roleId === 1 || user.roleId === 2 ? (
      <Inplace
        closable={false}
        active={activeEditor.field === field && activeEditor.id === rowData.id}
        onOpen={setEditor(field, rowData.id, rowData[field])}
      >
        <InplaceDisplay>
          <span
            className={`status-${rowData?.status?.toLowerCase()}`}
            data-pr-tooltip="Click to edit"
            onClick={() => setStatusType(rowData.status)}
          >
            {StatusfirstLetterUpperCase + rowData[field]?.slice(1)}
          </span>
          <Tooltip target=".status-tooltip" />
        </InplaceDisplay>
        <InplaceContent>{getStatusEditor(rowData, field)}</InplaceContent>
      </Inplace>
    ) : (
      <div>{StatusfirstLetterUpperCase + rowData[field]?.slice(1)}</div>
    );
  };
  const columns = [
    {
      field: "id",
      header: "ID",
    },
    {
      field: "date",
      header: "Date",
      body: dateBody,
    },
    {
      field: "time",
      header: "Time",
      body: timeBody,
    },
    {
      field: "duration",
      header: "Duration",
      body: durationBody,
    },
    {
      field: "parent.name",
      header: "Parent",
    },
    {
      field: "therapist.name",
      header: "Therapist",
    },
    {
      field: "status",
      header: "Status",
      body: statusBody,
      filter: true,
      filterPlaceholder: "Search by value",
      filterElement: statusFilter, // Custom filter element for dropdown
      filterMatchMode: "equals", // Use 'equals' since we are using dropdown
    },
  ];
  return (
    <div>
      <DataListing
        title="Appointments"
        subject={"Appointment"}
        data={appointments}
        columns={columns}
        onCreate={handleCreate}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <Dialog
        visible={appointmentModalVisible}
        onHide={() => {
          setAppointmentModalVisible(false);
          setEditMode(false);
          setNewAppointment({
            date: "",
            time: "",
            duration: 30,
            therapistId: "",
            parentId: "",
            mainConcerns: "",
            notes: "",
          });
        }}
        header="Appointment Modal"
        style={{ width: "40vw" }}
      >
        <div>
          <SingleAppointment
            editMode={editMode}
            newAppointment={newAppointment}
            setNewAppointment={setNewAppointment}
            handleSaveAppointment={handleSaveAppointment}
            allTherapists={allTherapists}
            allParents={allParents}
            showParents={user.roleId === 1 || user.roleId === 2 ? true : false}
            checkTimeAvailability={checkTimeAvailability}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AppointmentsList;
