import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
// import { useAuth } from '../../contexts/AuthContext';
import { getPayments, createPayment, updatePayment, deletePayment } from '../../api/paymentApi';
import SinglePayment from './SinglePayment';
import DataListing from '../Common/DataListing';
import { Dialog } from 'primereact/dialog';
import { formatDate } from '../../utils/dateutils';


const PaymentsList = () => {
    // const { user } = useAuth();
    const [payments, setPayments] = useState([]);
    const [paymentData, setPaymentData] = useState({
        amount: 0.00,
        dueDate: null,
        status: null,
        paidDate: null,
    });  //to be used for SinglePayment (Add/Edit)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const renderDate = (fieldName) => {
        return (rowData) => {
            return <span>{formatDate(rowData[fieldName])}</span>;
        };
    };
    
    const renderParentName = (rowData) => {
        return (<span>{rowData.user.name}</span>)
    };

    // Enum Mapping
    const paymentStatusLabels = {
        'pending': 'Pending',
        'paid': 'Paid',
        'overdue': 'Over Due'
    };
    const renderpaymentStatus = (rowData) => {
        return (<span>{paymentStatusLabels[rowData.status] || ''}</span>)
    };
    const [columns, setColumns] = useState([
        { field: 'subscriptionId', header: 'Subscription Id' },
        { field: 'amount', header: 'Amount' },
        {header: 'Parent', body:renderParentName },
        {header: 'Due Date',body:renderDate('dueDate') },
        {header: 'Status', body:renderpaymentStatus },
        {header: 'Paid Date', body:renderDate('paidDate') },

    ]);//to be used for DataListing

    useEffect(() => {
        fetchPayments();
    }, []);

    const fetchPayments = async () => {
        try {
            const response = await getPayments();
            // console.log("Payments", response)
            setPayments(response);
        } catch (error) {
            console.error('Failed to fetch payments', error);
        }
    };

    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleInlineEdit = async (paymentId, paymentData) => {
        await updatePayment(paymentId, paymentData);
        fetchPayments();
    };
    const handleDelete = async (id) => {
        try {
            await deletePayment(id);
            fetchPayments();
        } catch (error) {
            console.error('Failed to delete payment', error);
        }
    };

    const handleEdit = (rowData) => {
        setIsEdit(true);
        console.log("payment rowData", rowData);
        setPaymentData(rowData);
        setIsModalVisible(true);
    };

    // const handleCreate = () => {
    //     setIsEdit(false);
    //     setPaymentData({
    //         name: '',
    //         paymentType: '',
    //         details: ''
    //     });
    //     setIsModalVisible(true);
    // };

    const handleSubmit = async () => {
        try {
            let response = {}
            if (isEdit) {
                response = await updatePayment(paymentData.id, paymentData);
            } else {
                response = await createPayment(paymentData);
            }
            // console.log(response.data);
            responseAlert(response, 300);
            fetchPayments(); // Refresh the list after submission
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error saving payment', error);
        }
    };

    return (
        <>
            <DataListing
                data={payments}
                subject={"Payment"}
                columns={columns}
                onCreate={null}
                onEdit={handleEdit}
                onDelete={handleDelete} />
            <Dialog
                header="Payment Details"
                visible={isModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsModalVisible(false)}>
                <SinglePayment
                    payment={paymentData}
                    setPayment={setPaymentData}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                />
            </Dialog>
        </>
    );
};

export default PaymentsList;
