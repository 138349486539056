// components/SingleUser.js

import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
// import { InputSwitch } from 'primereact/inputswitch';
import { Password } from "primereact/password";
import { getRoles } from "../../api/roleApi";
import "../../styles/common.scss";
import { InputSwitch } from "primereact/inputswitch";
import { toast } from "react-toastify";

const SingleUser = ({
  user,
  setUser,
  onSubmit,
  isEdit,
  isChangingPassword,
  setIsChangingPassword,
}) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState(""); // Separate state for password
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const data = await getRoles();
        setRoles(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching roles", error);
      }
    };
    fetchRoles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("updated user", user);
  };
  const handlePasswordChange = (e) => {
    if (!isChangingPassword) {
      toast.error("Can't change password without checking the box", {
        autoClose: 200,
      });
      return;
    }
    const { name, value } = e.target;
    setPassword(value);
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // const handleSwitchChange = (e) => {
  //   setUser((prevState) => ({
  //     ...prevState,
  //     isActive: e.value,
  //   }));
  // };

  return (
    <div className="form-group">
      {/* <div className="form-group"></div> */}
      <div className="p-fluid">
        {/* <div  style={{ textAlign: 'center' }}>
          <label htmlFor="isActive">Active</label>
          <InputSwitch
            id="isActive"
            checked={user.isActive}
            onChange={handleSwitchChange}
          />
        </div> */}
        <div className="p-field mb-2">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={user.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="p-field mb-2">
          <label htmlFor="email">Email</label>
          <InputText
            id="email"
            name="email"
            value={user.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="p-field mb-2">
          <label htmlFor="password">Password</label>
          <Password
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder={
              isEdit ? "Leave blank to keep current password" : "Enter password"
            }
            required={!isEdit}
            disabled={!isChangingPassword}
            feedback={false}
          />
          <span className="flex align-items-center">
            <InputSwitch
              className="mt-2 "
              style={{ transform: " scale(0.7)" }}
              value={isChangingPassword}
              checked={isChangingPassword}
              onChange={() => {
                setIsChangingPassword(!isChangingPassword);
              }}
            />
            {isChangingPassword ? (
              <small className="mt-2 mx-1">Password change is enabled.</small>
            ) : (
              <small className="mt-2 mx-1">Change Password </small>
            )}
          </span>
        </div>
        <div className="p-field mb-2">
          <label htmlFor="roleId">Role</label>
          <Dropdown
            id="roleId"
            name="roleId"
            value={user.roleId}
            options={roles}
            onChange={handleChange}
            placeholder="Select a Role"
            optionLabel="name"
            optionValue="id"
            required
            disabled={loading}
          />
        </div>
        <Button
          label={isEdit ? "Update" : "Add"}
          style={{ marginTop: "1rem" }}
          className="btn-primary"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SingleUser;
