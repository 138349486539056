import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Slider } from "primereact/slider";
import React, { useEffect, useState } from "react";
import { formatDuration } from "../../utils/generalutils";

const SingleAppointment = ({
  newAppointment,
  setNewAppointment,
  //   services,
  handleSaveAppointment,
  editMode,
  allTherapists,
  showParents,
  allParents,
  checkTimeAvailability,
}) => {
  return (
    <div className=" mt-2">
      {editMode ? null : (
        <p className="fs-1 text-center font-bold">Place An Appointment</p>
      )}
      <div className="grid">
        {/* <div
              className="row mb-3"
              style={{ display: "flex", flexDirection: "row" }}
            > */}
        {showParents && (
          <div className="col-6">
            <label>Parent</label>
            <Dropdown
              value={newAppointment.parentId}
              onChange={(e) =>
                setNewAppointment((prev) => ({
                  ...prev,
                  parentId: e.value,
                }))
              }
              options={allParents}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Parent"
              className="full-width"
            />
          </div>
        )}
        <div className={`${showParents ? "col-6" : "col-12"}`}>
          <label>Therapist</label>
          <Dropdown
            value={newAppointment.therapistId}
            onChange={(e) =>
              setNewAppointment((prev) => ({
                ...prev,
                therapistId: e.value,
              }))
            }
            options={allTherapists}
            optionLabel="name"
            optionValue="id"
            placeholder="Select Therapist"
            className="full-width"
          />
        </div>

        <div className="col-4">
          <label>Date</label>
          <Calendar
            value={newAppointment.date ? new Date(newAppointment.date) : null}
            onChange={(e) => {
              setNewAppointment((prev) => ({
                ...prev,
                date: e.value,
              }));
            }}
            placeholder="Select Date"
            className=" full-width"
            minDate={new Date()}
          />
        </div>
        <div className="col-4 ">
          <label>Time</label>
          <InputText
            id="startTime"
            type="time"
            value={newAppointment.time || ""}
            onChange={(e) => {
              setNewAppointment((prev) => ({
                ...prev,
                time: e.target.value,
              }));
            }}
            className=" full-width"
          />
        </div>
        <div className="col-4 flex align-items-center justify-content-center">
          <Button
            className="btn-outline-secondary mt-3"
            label="Check Availibility"
            disabled={
              !newAppointment.time ||
              !newAppointment.date ||
              !newAppointment.therapistId
            }
            onClick={checkTimeAvailability}
          />
        </div>
        <div className="col-12 ">
          <label htmlFor="duration">Duration</label>
          <Slider
            className="mt-2 mb-2 full-width"
            id="duration"
            value={newAppointment.duration}
            onChange={(e) =>
              setNewAppointment((prev) => ({
                ...prev,
                duration: e.value,
              }))
            }
            min={30}
            max={360}
            step={15}
          />
          <span>
            Selected Duration: {formatDuration(newAppointment.duration)}
          </span>
        </div>
        {/* </div> */}
        <br />
        {/* <div
              className="row mb-3"
              style={{ display: "flex", flexDirection: "row" }}
              // className="grid"
              // className="vertical-align-baseline flex flex-row flex-wrap grid mx-2"
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   justifyContent: "center",
              //   alignContent: "center",
              // }}
            > */}

        {/* </div> */}
        {/* <div
              className="row "
              style={{ display: "flex", flexDirection: "row" }}
            > */}
        <div className="col-6 ">
          <label>Main Concerns</label>
          <InputTextarea
            value={newAppointment.mainConcerns}
            onChange={(e) =>
              setNewAppointment((prev) => ({
                ...prev,
                mainConcerns: e.target.value,
              }))
            }
            placeholder="Write Main Concerns"
            rows={3}
            className="full-width"
            // className="p-mb-2"
          />
        </div>

        <div className="col-6 ">
          <label>Notes</label>
          <InputTextarea
            value={newAppointment.notes}
            onChange={(e) =>
              setNewAppointment((prev) => ({
                ...prev,
                notes: e.target.value,
              }))
            }
            placeholder="Write Additional Notes"
            rows={3}
            className="full-width"
          />
        </div>
        {/* </div> */}
        <div className="col-12 mb-2 flex justify-content-center align-items-center">
          <Button
            label={editMode ? "Update Appointment" : "Add Appointment"}
            onClick={handleSaveAppointment}
            className="btn-primary"
          />
        </div>
      </div>
    </div>
  );
};

export default SingleAppointment;
